import * as React from "react"

import Category from "../../route/category_en"





// markup
const IndexPage = (props) => {
  return (
    <React.Fragment>
      <Category props={props} />
      </React.Fragment>
  )
}

export default IndexPage
